<template xmlns="http://www.w3.org/1999/html">
  <div class="teacher-requests">
    <loading v-if="isLoading" />
    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{ $t("g.searchLabel") }}</label>
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('g.searchHere')"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>
      <vue-good-table
        styleClass="vgt-table striped"
        :columns="tableHeader"
        :rows="tableBody"
        :search-options="{ enabled: true, externalQuery: searchTerm }"
        :sort-options="{ enabled: false }"
        :pagination-options="{ enabled: true, perPage: pageLength }"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'name'" class="text-wrap">
            <router-link
              :to="{
                name: 'showTeachersRequest',
                params: {
                  id: props.row.id,
                },
              }"
            >
              {{ props.row.name }}
            </router-link>
          </span>
          <span
            v-else-if="props.column.field === 'attachment'"
            class="text-wrap"
          >
            <a
              v-if="props.row.attachment"
              :href="props.row.attachment"
              target="_blank"
              class="attach"
            >
              {{ $t("teachersList.viewAttach") }}
            </a>
          </span>
          <span v-else-if="props.column.field === 'actions'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item @click="acceptTeacher(props.row.id)">
                  <feather-icon icon="CheckCircleIcon" class="mr-50" />
                  <span>{{ $t("teachersList.accept") }}</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteAlert(props.row.id)">
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
          <span v-else>
            {{ props.row[props.column.field] }}
          </span>
        </template>
        <template slot="emptystate">
          <span class="text-center" style="width: 100%; display: inline-block">
            {{ $t("g.no_data_for_table") }}
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>
<script>
import {
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BCard,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/loading/loading.vue";

export default {
  name: "TeachersRequests",
  components: {
    Loading,
    BFormGroup,
    BFormInput,
    BButton,
    BDropdown,
    BBadge,
    BFormSelect,
    BDropdownItem,
    BPagination,
    VueGoodTable,
    BCard,
  },
  data() {
    return {
      tableHeader: [
        {
          label: "#",
          field: "i",
        },
        {
          label: this.$t("teachersList.labels.name"),
          field: "name",
        },
        {
          label: this.$t("teachersList.labels.email"),
          field: "email",
        },
        {
          label: this.$t("teachersList.labels.phone"),
          field: "phone",
        },
        {
          label: this.$t("teachersList.labels.nationality"),
          field: "nationality",
        },
        {
          label: this.$t("teachersList.labels.attachment"),
          field: "attachment",
        },
        {
          label: this.$t("teachersList.labels.actions"),
          field: "actions",
        },
      ],
      tableBody: [],
      isLoading: false,
      pageLength: 5,
      searchTerm: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    deleteAlert(id) {
      this.$helpers.sweetAlertConfirm(
        this.$t("g.are_you_sure"),
        this.$t("g.you_wont_be_able_to_revert_this"),
        "warning",
        this.$t("g.yes_delete_it"),
        this.$t("g.cancel"),
        () => this.reject(id)
      );
    },
    reject(id) {
      this.$http
        .delete(`/admin/teachers/${id}`)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.tableBody = this.tableBody.filter((ele) => ele.id !== id);
            this.$helpers.makeToast(
              "success",
              response.data.message,
              response.data.message
            );
          }
        })
        .catch((err) => {
          console.log(err);
          this.$helpers.handleError(err);
        });
    },
    async getData() {
      this.isLoading = true;
      try {
        this.tableBody = [];
        const request = await this.$http.get("admin/teachers?approved=false");
        if (request.status === 200 || request.status === 201) {
          request.data.data.map(({ user, id }, i) => {
            if (user) {
              this.tableBody.push({
                i: i + 1,
                id,
                name: `${user?.first_name} ${user?.last_name}`,
                email: user?.email,
                phone: user?.phone[0].phone,
                nationality: user?.nationality?.name,
                birthdate: user?.birthdate,
                attachment: user?.media?.filter((img) => img?.name === "cv")[0]
                  ?.path,
              });
            }
          });
        }
      } catch (error) {
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
    async acceptTeacher(id) {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("is_approved", 1);
        const request = await this.$http.post(
          `admin/teachers/${id}?_method=put`,
          formData
        );
        if (request.status === 200 || request.status === 201) {
          this.$helpers.makeToast(
            "success",
            request.data.message,
            request.data.message
          );
          this.getData();
          this.isLoading = false;
        }
      } catch (error) {
        this.$helpers.handleError(error);
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/variables/_variables.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";

.list-board-style {
  .color-cube {
    display: inline-block;
    width: 60px;
    height: 30px;
    padding: 0.2rem;
  }
}
.attach {
  color: $primary-color !important;
}
</style>
